<!--
File: WorkCategoryEditForm.vue
Description: form for adding/editing a single Work Category.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-30">
          <md-field :class="[{ 'md-valid': !errors.has('description') }, { 'md-error': errors.has('description') }]">
            <label>{{ $t('stdCols.description') }} ({{ ($t('translate.ru')) }})</label>
            <md-input v-model="description" type="text" data-vv-name="description" required
              v-validate="modelValidations.description" />
          </md-field>

          <md-field
            :class="[{ 'md-valid': !errors.has('description_en') }, { 'md-error': errors.has('description_en') }]">
            <label>{{ $t('stdCols.description') }} ({{ ($t('translate.en')) }})</label>
            <md-input v-model="description_en" type="text" data-vv-name="description_en" required
              v-validate="modelValidations.description_en" />
          </md-field>
        </div>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'work-category-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        work_category_id: null,
        description: null,
        description_en: null,

        modelValidations: {
          description: { required: true, min: 1 },
          description_en: { required: true, min: 1 },
        }
      }
    },

    props: {
      oper: String
    },

    mounted() {
      const { work_category_id = null } = this.$route.params
      this.work_category_id = work_category_id
      if (this.oper === 'upd' && work_category_id) {
        this.$store.dispatch('LOAD_WORK_CATEGORY_BY_ID', work_category_id).then((res) => {
          const theWorkCategory = Array.isArray(res) ? res[0] : res
          this.description = theWorkCategory.description
          this.description_en = theWorkCategory.description_en
        })
      }
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) return

        const theWorkCategory = {
          description: this.description,
          description_en: this.description_en,
        }

        const reqData = this.oper === 'add' ? theWorkCategory : { id: this.work_category_id, work_category: theWorkCategory }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_WORK_CATEGORY`, reqData)
        } catch (err) {
          errDesc = err
        }

        this.savedMessage(errDesc, this.$t('label.category'), this.description, this.description_en)
        this.$nextTick(() => {
          this.$validator.reset()
        })
        this.onClose();
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.count() > 0
      }
    },

    watch: {
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
    